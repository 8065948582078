<template>
  <div>
    <slot :has-gravatar="hasGravatar"></slot>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { gravatarLoads } from '~/composables/getAvatarSrc';

const props = defineProps({
  gravatarUrl: {
    type: String as PropType<string>,
    required: true,
  },
});

const hasGravatar = ref(false);

const onLoad = () => {
  hasGravatar.value = true;
};

const onError = () => {
  hasGravatar.value = false;
};

gravatarLoads(props.gravatarUrl, onLoad, onError);
</script>
